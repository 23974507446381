import { useEffect, useState } from 'react';
import { Avatar, Box, Paper, Tooltip } from '@mui/material';
import ReactMapGL, { Marker, Popup } from 'react-map-gl';
import Supercluster from 'supercluster';

import { useValue } from '../../context/ContextProvider';
import { getRooms } from '../../actions/room';
import GeocoderInput from '../sidebar/GeocoderInput';
import PopupRoom from './PopupRoom';

import 'mapbox-gl/dist/mapbox-gl.css';
import './cluster.css';

const supercluster = new Supercluster({ radius: 75, maxZoom: 20 });

function ClusterMap()
{
  const { state: { filteredRooms }, dispatch, mapRef } = useValue();
  const [points, setPoints] = useState([]);
  const [clusters, setClusters] = useState([]);
  const [bounds, setBounds] = useState([-180, -85, 180, 85]);
  const [zoom, setZoom] = useState(0);
  const [popupInfo, setPopupInfo] = useState(null);

  function effectGetRooms()
  {
    getRooms(dispatch);
  }

  function effectSetPoints()
  {
    const points = filteredRooms.map((room) => ({
      type: 'Feature',
      properties:
      {
        cluster     : false,
        roomId      : room._id,
        price       : room.price,
        title       : room.title,
        description : room.description,
        lng         : room.lng,
        lat         : room.lat,
        images      : room.images,
        uPhoto      : room.uPhoto,
        uName       : room.uName
      },
      geometry: { type: 'Point', coordinates: [parseFloat(room.lng), parseFloat(room.lat)] }
    }));

    setPoints(points);
  }

  function effectSetClusters()
  {
    supercluster.load(points);
    setClusters(supercluster.getClusters(bounds, zoom));
  }

  function effectSetBounds()
  {
    if (mapRef.current)
    {
      setBounds(mapRef.current.getMap().getBounds().toArray().flat());
    }
  }

  function generateCluster(cluster)
  {
    const { cluster: isCluster, point_count } = cluster.properties;
    const [longitude, latitude] = cluster.geometry.coordinates;
    let result;

    function handleclickShowPopup()
    {
      setPopupInfo(cluster.properties)
    }

    if (isCluster)
    {
      function adjustClusterZoom()
      {
        const zoom = Math.min(supercluster.getClusterExpansionZoom(cluster.id), 20);

        mapRef.current.flyTo({ center: [longitude, latitude], zoom, speed: 1 });
      }

      result =
        <Marker key={ `cluster-${cluster.id}` } longitude={ longitude } latitude={ latitude }>
          <div className='cluster-marker'
            style={{ width: `${10 + (point_count / points.length) * 20}px`, height: `${10 + (point_count / points.length) * 20}px`}}
            onClick={ adjustClusterZoom }>
            { point_count }
          </div>
        </Marker>;
    }
    else                        // single room
    {
      result =
        <Marker key={ `room-${cluster.properties.roomId}` } longitude={ longitude } latitude={ latitude }>
          <Tooltip title={ cluster.properties.uName }>
            <Avatar src={ cluster.properties.uPhoto } component={ Paper } elevation={ 2 } onClick={ handleclickShowPopup }/>
          </Tooltip>
        </Marker>;
    }

    return result;
  }

  useEffect(effectGetRooms, [dispatch]);
  useEffect(effectSetPoints, [filteredRooms]);
  useEffect(effectSetClusters, [points, zoom, bounds]);
  useEffect(effectSetBounds, [mapRef]);
  
  const clusterList = clusters.map(generateCluster);

  const result =
    <Box sx={{ height: '85vh' }}>
      <ReactMapGL reuseMaps initialViewState={{ latitude: 51.5072, longitude: 0.1276 }}
        mapboxAccessToken={ process.env.REACT_APP_MAP_TOKEN } mapStyle='mapbox://styles/mapbox/streets-v11' ref={ mapRef }
        onZoomEnd={ (e) => setZoom(Math.round(e.viewState.zoom)) }>
        { clusterList }
        <GeocoderInput />
        { popupInfo &&
          <Popup longitude={ popupInfo.lng } latitude={ popupInfo.lat } maxWidth='auto'
            closeOnClick={ false } focusAfterOpen={ false } onClose={ () => setPopupInfo(null) }>
            <PopupRoom { ...{ popupInfo } } />
          </Popup> }
      </ReactMapGL>
    </Box>;

  return result;
}

export default ClusterMap;