import { Box, Slider, Typography } from '@mui/material';
import { useValue } from '../../context/ContextProvider';

const marks =
[
  { value: 0, label: '$0' },
  { value: 10, label: '$10' },
  { value: 20, label: '$20' },
  { value: 30, label: '$30' },
  { value: 40, label: '$40' },
  { value: 50, label: '$50' }
];

function PriceSlider()
{
  const { state: { priceFilter }, dispatch } = useValue();

  function handleSliderChange(_, price)
  {
    console.log('PriceSlider.js > PriceSlider > handleSliderChange | price:', price)
    dispatch({ type: 'FILTER_PRICE', payload: price });
  }

  const result =
    <Box sx={{ mt: 5 }}>
      <Typography>Max Price: { '$ ' + priceFilter }</Typography>
      <Slider min={ 0 } max={ 50 } defaultValue={ 50 } valueLabelDisplay='auto' marks={ marks }
        value={ priceFilter } onChange={ handleSliderChange }  />
    </Box>;

  return result;
};

export default PriceSlider;