import { Alert, Snackbar } from '@mui/material';
import { useValue } from '../context/ContextProvider';

function Notification()
{
  const { state: { alert }, dispatch } = useValue();

  function handleClose(event, reason)
  {
    if (reason !== 'clickaway')
    {
      dispatch({ type: 'UPDATE_ALERT', payload: { ...alert, open: false } });
    }
  }


  const result =
    <Snackbar open={ alert.open } autoHideDuration={ 6000 } onClose={ handleClose } anchorOrigin={{ vertical: 'top', horizontal: 'center'}}>
      <Alert onClose={ handleClose } severity={ alert.severity }
        sx={{ width: '100%'}} variant='filled' elevation={ 6 }>
        { alert.message }
      </Alert>
    </Snackbar>;

  return result;
}

export default Notification;
