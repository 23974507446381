import { useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import ReactMapGL, { GeolocateControl, Marker, NavigationControl } from 'react-map-gl';
import { useValue } from '../../../context/ContextProvider';
import 'mapbox-gl/dist/mapbox-gl.css';
import Geocoder from './Geocoder';

function AddLocation()
{
  const { state: { location: { lng, lat }, currentUser },  dispatch } = useValue();
  const mapRef = useRef();

  function updateLocation()
  {
    function onFulfilledFetch(response)
    {
      return response.json();
    }

    function onFulfilledJson(data)
    {
      dispatch({ type: 'UPDATE_LOCATION', payload: { lng: data.longitude, lat: data.latitude } });
    }

    const storedLocation = JSON.parse(localStorage.getItem(currentUser.id))?.location;

    if (!lng && !lat && !storedLocation?.lng && !storedLocation?.lat)
    {
      fetch('https://ipapi.co/json').then(onFulfilledFetch).then(onFulfilledJson);
    }
  }

  function effectCenterMap()
  {
    if ((lng || lat) && mapRef.current)
    {
      mapRef.current.flyTo({ center: [lng, lat] });
    }
  }

  function handleDragEnd(e)
  {
    dispatch({ type: 'UPDATE_LOCATION', payload: { lng: e.lngLat.lng, lat: e.lngLat.lat } });
  }

  function geolocate(e)
  {
    dispatch({ type: 'UPDATE_LOCATION', payload: { lng: e.coords.longitude, lat: e.coords.latitude } });
  }

  useEffect(effectCenterMap, [lng, lat]);
  useEffect(updateLocation, [dispatch, lat, lng]);

  const result =
    <Box sx={{ height: 400, position: 'relative' }}>
      <ReactMapGL ref={ mapRef } mapboxAccessToken={ process.env.REACT_APP_MAP_TOKEN }
        initialViewState={{ longitude: lng, latitude: lat, zoom: 8 }} mapStyle='mapbox://styles/mapbox/streets-v11'>
        <Marker latitude={ lat } longitude={ lng } draggable onDragEnd={ handleDragEnd }/>
        <NavigationControl position='bottom-right' />
        <GeolocateControl position='top-left' trackUserLocation onGeolocate={ geolocate } />
        <Geocoder />
      </ReactMapGL>
    </Box>;

  return result;
};

export default AddLocation;