import { useState } from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

function PasswordField({ passwordRef, id='password', label='Password' })
{
  const [ showPassword, setShowPassword ]= useState(false);

  function handleClick()
  {
    setShowPassword(prevValue => !prevValue);
  }

  function handleMouseDown(e)
  {
    e.preventDefault();
  }

  const result =
    <TextField margin='normal' variant='standard' id={ id } label={ label }
      type={ showPassword ? 'text' : 'password' } fullwidth inputRef={ passwordRef }
      required inputProps={{ minLength: 3 }}
      InputProps={{ endAdornment:
        (<InputAdornment position='end'>
          <IconButton onClick={ handleClick } onMouseDown={ handleMouseDown }>
            { showPassword ? <VisibilityOff /> : <Visibility /> }
          </IconButton>
        </InputAdornment>) }} 
      />;
      
  return result;
}

export default PasswordField;
