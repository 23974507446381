import deleteImages from './utils/deleteImages';
import fetchData from './utils/fetchData';

const url = process.env.REACT_APP_SERVER_URL + '/room';

export async function createRoom(room, currentUser, dispatch)
{
  dispatch({ type: 'START_LOADING' });

  const result = await fetchData({ url, body: room, token: currentUser?.token }, dispatch);
  
  if (result)
  {
    dispatch({ type: 'UPDATE_ALERT', payload: { open: true, severity: 'success', message: 'Room added successfully' } });
    clearRoom(dispatch, currentUser);
    dispatch({ type: 'UPDATE_SECTION', payload: 0 });
    dispatch({ type: 'UPDATE_ROOM', payload: result });
  }

  dispatch({ type: 'END_LOADING' });
};

export async function getRooms(dispatch)
{
  const result = await fetchData({ url, method: 'GET' }, dispatch);

  console.log('actions/rooms.js > getRooms | result:', result);

  if (result)
  {
    dispatch({ type: 'UPDATE_ROOMS', payload: result });
  }
}

export async function deleteRoom(room, currentUser, dispatch)
{
  dispatch({ type: 'START_LOADING' });

  const result = await fetchData({ url: `${url}/${room._id}`, method: 'DELETE', token: currentUser?.token }, dispatch);

  if (result)
  {
    dispatch({ type: 'UPDATE_ALERT', payload: { open: true, severity: 'success', message: 'Room deleted successfully' } });
    dispatch({ type: 'DELETE_ROOM', payload: result._id });
    deleteImages(room.images, room.uid);
  }

  dispatch({ type: 'END_LOADING' });
};

export async function updateRoom(room, currentUser, dispatch, updatedRoom, deletedImages)
{
  dispatch({ type: 'START_LOADING' });

  const result = await fetchData({ url: `${url}/${updatedRoom._id}`, method: 'PATCH', body: room, token: currentUser?.token }, dispatch);
  if (result)
  {
    dispatch({ type: 'UPDATE_ALERT', payload: { open: true, severity: 'success', message: 'Room updated successfully'} });
    clearRoom(dispatch, currentUser, deletedImages, updatedRoom);
    dispatch({ type: 'UPDATE_SECTION', payload: 0 });
    dispatch({ type: 'UPDATE_ROOM', payload: result });
  }

  dispatch({ type: 'END_LOADING' });
};

export function clearRoom(dispatch, currentUser, images = [], updatedRoom = null)
{
  dispatch({ type: 'RESET_ROOM' });
  
  localStorage.removeItem(currentUser.id);

  if (updatedRoom)
  {
    deleteImages(images, updatedRoom.uid);
  }
  else
  {
    deleteImages(images, currentUser.id);
  }
};

export function storeRoom(location, details, images, updatedRoom, deletedImages, addedImages, userId)
{
  let result;
  
  if (location.lng || location.lat || details.price || details.title || details.description || images.length)
  {
    localStorage.setItem(userId, JSON.stringify({ location, details, images, updatedRoom, deletedImages, addedImages }));
    result = true;
  }
  else
  {
    result =false;
  }

  return result;
};