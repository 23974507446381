import { ImageList } from '@mui/material';
import React from 'react';
import ProgressItem from './ProgressItem';

function ProgressList({ files })
{
  const result =
    <ImageList rowHeight={250}
      sx={{ '&.MuiImageList-root': { gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))!important' }}}>
      {files.map((file, index) => (<ProgressItem file={file} key={index} />))}
    </ImageList>;

  return result;
};

export default ProgressList;