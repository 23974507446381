import { useEffect } from 'react';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { useValue } from '../../context/ContextProvider';

const geocoderConfig = { marker: false, accessToken: process.env.REACT_APP_MAP_TOKEN };
const ctrl = new MapboxGeocoder(geocoderConfig);

function GeocoderInput()
{
  const { mapRef, containerRef, dispatch } = useValue();

  function updateChildren()
  {
    console.log('Geocodernput.js > GeocoderInput > updateChildren | containerRef:', containerRef);

    if (containerRef?.current?.children[0])
    {
      containerRef.current.removeChild(containerRef.current.children[0]);
    }

    containerRef.current.appendChild(ctrl.onAdd(mapRef.current.getMap()));

    function handleResult(e)
    {
      const coords = e.result.geometry.coordinates;
      
      dispatch({ type: 'FILTER_ADDRESS', payload: { lng: coords[0], lat: coords[1] } });
    }

    function handleClear()
    {
      dispatch({ type: 'CLEAR_ADDRESS' });
    }

    ctrl.on('result', handleResult);
    ctrl.on('clear', handleClear);
  }

  useEffect(updateChildren, [containerRef, dispatch, mapRef]);

  return null;
};

export default GeocoderInput;