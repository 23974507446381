import { v4 as uuidv4 } from 'uuid';
import fetchData from './utils/fetchData';
import uploadFile from '../firebase/uploadFile';

const url = process.env.REACT_APP_SERVER_URL + '/user';

export async function register(user, dispatch)
{
  dispatch({ type: 'START_LOADING' });

  const result = await fetchData({ url: url + '/register', body: user }, dispatch);

  if (result)
  {
    dispatch({ type: 'UPDATE_USER', payload: result });
    dispatch({ type: 'CLOSE_LOGIN' });
    dispatch({ type: 'UPDATE_ALERT', payload: { open: true, severity: 'success', message: 'Account created successfully...' } });
  }

  dispatch({ type: 'END_LOADING' });
};


export async function login(user, dispatch)
{
  dispatch({ type: 'START_LOADING' });

  const result = await fetchData({ url: url + '/login', body: user }, dispatch);
  if (result)
  {
    dispatch({ type: 'UPDATE_USER', payload: result });
    dispatch({ type: 'CLOSE_LOGIN' });
  }

  dispatch({ type: 'END_LOADING' });
};


export async function updateProfile(currentUser, updatedFields, dispatch)
{
  dispatch({ type: 'START_LOADING' });

  const { name, file } = updatedFields;
  let body = { name };

  console.log('actions/user.js > updateProfile | name:', name);
  console.log('actions/user.js > updateProfile | file:', file);
  console.log('actions/user.js > updateProfile | body:', body);

  try
  {
    if (file)
    {
      const imageName = uuidv4() + '.' + file?.name?.split('.')?.pop();
      const filePath = `profile/${currentUser?.id}/${imageName}`;

      console.log('actions/user.js > updateProfile | imageName:', imageName);
      console.log('actions/user.js > updateProfile | filePath:', filePath);
      
      const photoURL = await uploadFile(file, filePath);
      // const photoURL = '';

      console.log('actions/user.js > updateProfile | photoURL:', photoURL);

      body = { ...body, photoURL };
    }

    const result = await fetchData({ url: url + '/updateProfile', method: 'PATCH', body, token: currentUser.token }, dispatch );

    if (result)
    {
      dispatch({ type: 'UPDATE_USER', payload: { ...currentUser, ...result } });
      dispatch({ type: 'UPDATE_ALERT', payload: { open: true, severity: 'success', message: 'Profile updated successfully' }});
      dispatch({ type: 'UPDATE_PROFILE', payload: { open: false, file: null, photoURL: result.photoURL }});
    }
  }
  catch (error)
  {
    dispatch({ type: 'UPDATE_ALERT', payload: { open: true, severity: 'error', message: error.message }});

    console.log('actions/user.js > updateProfile > catch | error', error);
  }

  dispatch({ type: 'END_LOADING' });
}


export async function getUsers(dispatch, currentUser)
{
  const result = await fetchData({ url, method: 'GET', token: currentUser.token }, dispatch);

  if (result)
  {
    dispatch({ type: 'UPDATE_USERS', payload: result });
  }
};


export function updateStatus(updatedFields, userId, dispatch, currentUser)
{
  const promiseFetchData = fetchData({ url: `${url}/updateStatus/${userId}`, method: 'PATCH', token: currentUser.token, body: updatedFields }, dispatch);

  return promiseFetchData;
};


export function logout(dispatch)
{
  dispatch({ type: 'UPDATE_USER', payload: null });
  dispatch({ type: 'RESET_ROOM' });
  dispatch({ type: 'UPDATE_USERS', payload: [] });
};