import MapBoxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { useControl } from 'react-map-gl';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import { useValue } from '../../../context/ContextProvider';

function Geocoder()
{
  const { dispatch } = useValue();
  const geocoderOptions =
  {
    accessToken: process.env.REACT_APP_MAP_TOKEN,
    marker: false,
    collapsed: true
  }
  const ctrl = new MapBoxGeocoder(geocoderOptions);

  function onResult(e)
  {
    const coords = e.result.geometry.coordinates;

    dispatch({ type: 'UPDATE_LOCATION', payload: { lng: coords[0], lat: coords[1] } });
  }

  useControl(() => ctrl);

  ctrl.on('result', onResult);

  return null;
};

export default Geocoder;