import { useEffect, useRef } from 'react';
import { BottomNavigation, BottomNavigationAction, Box, Paper } from '@mui/material';
import { AddLocationAlt, Bed, LocationOn } from '@mui/icons-material';

import { useValue } from '../context/ContextProvider';
import ClusterMap from './map/ClusterMap';
import Rooms from './rooms/Rooms';
import AddRoom from './addRoom/AddRoom';
import Protected from './protected/Protected';

function BottomNav()
{
  const { state: { section }, dispatch } = useValue();
  const ref = useRef();

  function effectScrollTop()
  {
    ref.current.ownerDocument.body.scrollTop = 0;
  }

  function handleSectionChange(e, newValue)
  {
    dispatch({ type: 'UPDATE_SECTION', payload: newValue })
  }
  
  useEffect(effectScrollTop, [section]);
  
  const result =
    <Box ref={ ref }>
      {
        {
          0: <ClusterMap />,
          1: <Rooms />,
          2: <Protected> <AddRoom /> </Protected>
        }[section]
      }
      <Paper elevation={3} sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 2 }}>
        <BottomNavigation showLabels value={ section } onChange={ handleSectionChange }>
          <BottomNavigationAction label='Map' icon={<LocationOn />} />
          <BottomNavigationAction label='Rooms' icon={<Bed />} />
          <BottomNavigationAction label='Add' icon={<AddLocationAlt />} />
        </BottomNavigation>
      </Paper>
    </Box>;

    return result;
};

export default BottomNav;