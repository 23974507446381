import { useEffect } from 'react';
import { useValue } from '../context/ContextProvider';
import { jwtDecode } from 'jwt-decode';
import { storeRoom } from '../actions/room';
import { logout } from '../actions/user';

function useCheckToken()
{
  const { state: { currentUser, location, details, images, updatedRoom, deletedImages, addedImages }, dispatch } = useValue();

  function effectCheckUserStatus()
  {
    if (currentUser)
    {
      const decodedToken = jwtDecode(currentUser.token);

      if (decodedToken.exp * 1000 < new Date().getTime())
      {
        storeRoom( location, details, images, updatedRoom, deletedImages, addedImages, currentUser.id);
        logout(dispatch);
      }
    }
  }

  useEffect(effectCheckUserStatus, [currentUser, dispatch, addedImages, deletedImages, details, images, location, updatedRoom]);
}

export default useCheckToken;