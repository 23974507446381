import { Fragment, useEffect } from 'react';
import { Dashboard, Settings, Logout } from '@mui/icons-material';
import { ListItemIcon, Menu, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { useValue } from '../../context/ContextProvider';
import useCheckToken from '../../hooks/useCheckToken';
import { Profile } from './Profile';
import { logout } from '../../actions/user';
import { storeRoom } from '../../actions/room';

function UserMenu({ anchorUserMenu, setAnchorUserMenu })
{
  useCheckToken();

  const {dispatch, state: { currentUser, location, details, images, updatedRoom, deletedImages, addedImages } } = useValue();
  const navigate = useNavigate();

  function handleCloseUserMenu()
  {
    setAnchorUserMenu(null);
  }

  function handleLogout()
  {
    storeRoom(location, details, images, updatedRoom, deletedImages, addedImages, currentUser.id);
    logout(dispatch);
  };

  function showProfileModal()
  {
    dispatch({ type: 'UPDATE_PROFILE', payload: { open: true, file: null, photoURL: currentUser?.photoURL }});
  }

  function effectBeforeUnloadListener()
  {
    function storeBeforeLeave(e)
    {
      if (storeRoom(location, details, images, updatedRoom, deletedImages, addedImages, currentUser.id))
      {
        e.preventDefault();
        e.returnValue = true;
      }
    };

    window.addEventListener('beforeunload', storeBeforeLeave);

    function cleanup()
    {
      window.removeEventListener('beforeunload', storeBeforeLeave);
    }

    return cleanup;
  }

  useEffect(effectBeforeUnloadListener, [location, details, images, addedImages, currentUser.id, deletedImages, updatedRoom]);

  const result =
    <Fragment>
      <Menu anchorEl={ anchorUserMenu } open={ Boolean(anchorUserMenu) }
        onClose={ handleCloseUserMenu } onClick={ handleCloseUserMenu }>
        { !currentUser.google &&
          <MenuItem onClick={ showProfileModal }>
            <ListItemIcon>
              <Settings fontSize='small' />
            </ListItemIcon>
            Profile
          </MenuItem>
        }

        <MenuItem onClick={() => navigate('dashboard')}>
          <ListItemIcon>
            <Dashboard fontSize="small" />
          </ListItemIcon>
          Dashboard
        </MenuItem>

        <MenuItem onClick={ handleLogout }>
          <ListItemIcon>
            <Logout fontSize='small' />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
      <Profile />
    </Fragment>;

  return result;
}

export default UserMenu;
