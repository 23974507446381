import { Box, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import { useValue } from '../../../context/ContextProvider';

const COLORS = ['#00C49F', '#0088FE', '#FFBB28', '#FF8042'];

const RADIAN = Math.PI / 180;

function generatePieLabel({ cx, cy, midAngle, innerRadius, outerRadius, percent })
{
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  const result =
    <text x={ x } y={ y } fill='white' textAnchor={ x > cx ? 'start' : 'end' } dominantBaseline='central'>
      { `${(percent * 100).toFixed(0)}%` }
    </text>;

  return result;
};

export default function PieRoomsCost()
{
  const { state: { rooms } } = useValue();
  const [costGroups, setCostGroups] = useState([]);

  function effectSetCostGroups()
  {
    let free = 0,
        lessThan15 = 0,
        between15And35 = 0,
        moreThan35 = 0;
    
    function setCostgroup(room)
    {
      let result;

      if (room.price === 0)
      {
        free++;
      }
      else
      {
        if (room.price < 15)
        {
          lessThan15++
        }
        else
        {
          if (room.price <= 35)
          {
            between15And35++;
          }
          else
          {
            moreThan35++;
          }
        }
      }

      return result;
    }

    rooms.forEach(setCostgroup);

    setCostGroups([
      { name: 'Free stay', qty: free },
      { name: 'Less than $15', qty: lessThan15 },
      { name: 'Between $15 & $35', qty: between15And35 },
      { name: 'More than $35', qty: moreThan35 }
    ]);
  }

  function generateCell(entry, index)
  {
    return <Cell key={ `cell-${index}` } fill={ COLORS[index % COLORS.length] } />;
  };

  function generateLegendElement(color, i)
  {
    const result =
      <Stack key={ color } alignItems='center' spacing={ 1 }>
        <Box sx={{ width: 20, height: 20, background: color }} />
        <Typography variant='body2' sx={{ opacity: 0.7 }}>
          { costGroups[i]?.name }
        </Typography>
      </Stack>;

    return result;
  }

  const colorLegend = COLORS.map(generateLegendElement);

  useEffect(effectSetCostGroups, [rooms]);

  const result =
    <Box
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', flexWrap: 'wrap' }}>
      <PieChart width={ 200 } height={ 200 }>
        <Pie data={ costGroups } labelLine={ false } label={ generatePieLabel }
          outerRadius={ 80 } fill='#8884d8' dataKey='qty'>
          { costGroups.map(generateCell) }
        </Pie>
        <Tooltip />
      </PieChart>

      <Stack gap={ 2 }>
        <Typography variant='h6'>Rooms prices</Typography>
        <Box sx={{ display: 'flex', gap: 3, flexWrap: 'wrap' }}>
          { colorLegend }
        </Box>
      </Stack>
    </Box>;

  return result;
}