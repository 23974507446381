import { Fragment } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Dashboard from './pages/dashboard/Dashboard';
import Notification from './components/Notification';
import Loading from './components/Loading';
import Home from './pages/Home';
import Room from './components/rooms/Room';

function App()
{
  const result =
    <Fragment>
      <Loading />
      <Notification />
      <BrowserRouter>
        <Routes>
          <Route path="dashboard/*" element={ <Dashboard /> } />
          <Route path="*" element={ <Home /> } />
        </Routes>
      </BrowserRouter>
      <Room />
    </Fragment>;

  return result;
}

export default App;