import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { storage } from './config';

function uploadFile(file, filePath)
{
  console.log('uploadFile.js > uploadFile | file:', file);
  console.log('uploadFile.js > uploadFile | filePath:', filePath);
  console.log('uploadFile.js > uploadFile | storage:', storage);

  return new Promise(async (resolve, reject) => {
    const storageRef = ref(storage, filePath);

    try
    {
      await uploadBytes(storageRef, file);
      const url = await getDownloadURL(storageRef);
      resolve(url);
    }
    catch (error)
    {
      reject(error);
    }
  });
}

export default uploadFile;