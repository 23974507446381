import { Backdrop, CircularProgress } from '@mui/material';
import { useValue } from '../context/ContextProvider';

function Loading()
{
  const { state: { loading } } = useValue();

  const result =
    <Backdrop open={ loading }
      sx={{ zIndex: (theme)=> theme.zIndex.modal + 1 }}>
      <CircularProgress sx={{ color: 'white' }}/>
    </Backdrop>
  return result;
}

export default Loading;
