import { Fragment } from 'react';

import Login from '../components/user/Login';
import NavBar from '../components/NavBar';
import BottomNav from '../components/BottomNav';

function Home()
{
  const result =
    <Fragment>
      <Login />
      <NavBar />
      <BottomNav />
    </Fragment>;

  return result;
}

export default Home;