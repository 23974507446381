import { Lock } from '@mui/icons-material';
import { Alert, AlertTitle, Button, Container } from '@mui/material';
import { useValue } from '../../context/ContextProvider';

function AccessMessage()
{
  const { dispatch } = useValue();

  const result =
    <Container sx={{ py: 10 }}>
      <Alert severity='error' variant='outlined'>
        <AlertTitle>Access forbidden</AlertTitle>
        Please login or register to access this page
        <Button variant='outlined' sx={{ ml: 2 }} startIcon={ <Lock /> } onClick={() => dispatch({ type: 'OPEN_LOGIN' })}>
          Login
        </Button>
      </Alert>
    </Container>;

  return result;
};

export default AccessMessage;