import { useEffect, useMemo, useState } from 'react';
import { Avatar, Box, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import { useValue } from '../../../context/ContextProvider';
import { getUsers } from '../../../actions/user';
import moment from 'moment';
import UsersActions from './UsersActions';

function Users({ setSelectedLink, link })
{
  const { state: { users, currentUser }, dispatch } = useValue();

  const [pageSize, setPageSize] = useState(5);
  const [rowId, setRowId] = useState(null);

  function effectGetUsers()
  {
    setSelectedLink(link);

    if (users.length === 0)
    {
      getUsers(dispatch, currentUser);
    };
  }

  useEffect(effectGetUsers, [dispatch, link, setSelectedLink, users.length, currentUser]);


  function memoPopulateGrid()
  {
    const result =
    [
      {
        field: 'photoURL',
        headerName: 'Avatar',
        width: 60,
        renderCell: (params) => <Avatar src={params.row.photoURL} />,
        sortable: false,
        filterable: false,
      },
      { field: 'name', headerName: 'Name', width: 170 },
      { field: 'email', headerName: 'Email', width: 200 },
      {
        field: 'role',
        headerName: 'Role',
        width: 100,
        type: 'singleSelect',
        valueOptions: ['basic', 'editor', 'admin'],
        editable: currentUser?.role === 'admin'
      },
      {
        field: 'active',
        headerName: 'Active',
        width: 100,
        type: 'boolean',
        editable: currentUser?.role === 'admin'
      },
      {
        field: 'createdAt',
        headerName: 'Created At',
        width: 200,
        renderCell: (params) => moment(params.row.createdAt).format('YYYY-MM-DD HH:MM:SS')
      },
      { field: '_id', headerName: 'Id', width: 220 },
      {
        field: 'actions',
        headerName: 'Actions',
        type: 'actions',
        renderCell: (params) => (<UsersActions {...{ params, rowId, setRowId }} />)
      }
    ];

    return result;
  }

  const columns = useMemo(memoPopulateGrid, [rowId, currentUser?.role]);

  function handleGetRowSpacing(params)
  {
    const result =
    {
      top: params.isFirstVisible ? 0 : 3,
      bottom: params.isLastVisible ? 0 : 3
    }

    return result;
  }

  function setRowBackgroundColor(theme)
  {
    return theme.palette.mode === 'light' ? grey[200] : grey[900];
  }

  function handleCellEditCommit(params)
  {
    setRowId(params.id);
  }

  const result =
    <Box sx={{ height: 400, width: '100%' }}>
      <Typography variant='h3' component='h3' sx={{ textAlign: 'center', mt: 3, mb: 3 }}>
        User management
      </Typography>
      <DataGrid columns={columns} rows={users} getRowId={(row) => row._id} rowsPerPageOptions={[5, 10, 20]}
        pageSize={pageSize} onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        getRowSpacing={ handleGetRowSpacing } sx={{ [`& .${gridClasses.row}`]: { bgcolor: setRowBackgroundColor } }}
        onCellEditCommit={ handleCellEditCommit }
      />
    </Box>;

  return result;
};

export default Users;