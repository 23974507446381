import { Box, IconButton, Tooltip } from '@mui/material';
import { Delete, Edit, Preview } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import { useValue } from '../../../context/ContextProvider';
import { clearRoom, deleteRoom } from '../../../actions/room';

function RoomsActions({ params })
{
  const { _id, lng, lat, price, title, description, images, uid } = params.row;
  const { dispatch, state: { currentUser, updatedRoom, addedImages, images: newImages } } = useValue();
  const navigate = useNavigate();
  
  function handlePreview()
  {
    dispatch({ type: 'UPDATE_ROOM', payload: params.row });
  }

  function handleEdit()
  {
    if (updatedRoom)
    {
      clearRoom(dispatch, currentUser, addedImages, updatedRoom);
    }
    else
    {
      clearRoom(dispatch, currentUser, newImages);
    }
  
    dispatch({ type: 'UPDATE_LOCATION', payload: { lng, lat } });
    dispatch({ type: 'UPDATE_DETAILS', payload: { price, title, description } });
    dispatch({ type: 'UPDATE_IMAGES', payload: images });
    dispatch({ type: 'UPDATE_UPDATED_ROOM', payload: { _id, uid } });
    dispatch({ type: 'UPDATE_SECTION', payload: 2 });
    navigate('/');
  };

  function handleDelete()
  {
    deleteRoom(params.row, currentUser, dispatch);
  }
  
  const result =
    <Box>
      <Tooltip title='View room details'>
        <IconButton onClick={ handlePreview } >
          <Preview />
        </IconButton>
      </Tooltip>
      <Tooltip title='Edit room'>
        <IconButton onClick={ handleEdit }>
          <Edit />
        </IconButton>
      </Tooltip>
      <Tooltip title='Delete room'>
        <IconButton onClick={ handleDelete }>
          <Delete />
        </IconButton>
      </Tooltip>
    </Box>;

  return result;
};

export default RoomsActions;