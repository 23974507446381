import { useState,  useRef, useEffect } from 'react';
import { Close, Send } from '@mui/icons-material';
import { Dialog, DialogContent, DialogContentText, DialogTitle, IconButton, TextField, DialogActions, Button } from '@mui/material';
import { useValue } from '../../context/ContextProvider';

import PasswordField from './PasswordField';
import { login, register } from '../../actions/user';
import GoogleOneTapLogin from './GoogleOneTapLogin';

function Login()
{
  const { state: { openLogin }, dispatch } = useValue();
  const [ title, setTitle ] = useState('Login');
  const [ isRegistered, setIsRegistered ] = useState(false);

  const nameRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();
  const confirmPasswordRef = useRef();

  function handleClose()
  {
    dispatch({ type: 'CLOSE_LOGIN' });
  }

  function handleSubmit(e)
  {
    e.preventDefault();

    const email = emailRef.current.value;
    const password = passwordRef.current.value;

    if (!isRegistered)
    {
      login({ email, password }, dispatch);
    }
    else
    {
      const name = nameRef.current.value;
      const confirmPassword = confirmPasswordRef.current.value;
  
      if (password !== confirmPassword)
      {
        dispatch({ type: 'UPDATE_ALERT', payload: { open: true, severity: 'error', message: 'Passwords do not match' } });
      }
      else
      {
        register({ name, email, password }, dispatch); 
      }
    }
  }

  useEffect( () => { isRegistered ? setTitle('Register') : setTitle('Login') }, [isRegistered]);

  const result =
    <div>
      <Dialog open={ openLogin } onClose={ handleClose }>
        <DialogTitle>
          { title }
          <IconButton
            sx={ { position: 'absolute', top: 8, right: 8, color: (theme) => theme.palette.grey[500] } }
            onClick={ handleClose }>
            <Close />
          </IconButton>
        </DialogTitle>
        <form onSubmit={ handleSubmit }>
          <DialogContent dividers>
            <DialogContentText>
              Please fill in the form:
            </DialogContentText>
            { isRegistered &&
                <TextField autoFocus margin='normal' variant='standard' id='name' label='Name' type='text'
                  fullwidth inputRef={ nameRef } inputProps={{ minLength: 2 }} required /> }

            <TextField autoFocus={ !isRegistered } margin='normal' variant='standard' id='email'
              label='Email'  type='email' fullwidth inputRef={ emailRef } required />

            <PasswordField { ...{ passwordRef} }/>

            { isRegistered && <PasswordField passwordRef={ confirmPasswordRef } id='confirmPassword' label='Confirm Password' /> }
          </DialogContent>
          <DialogActions>
            <Button sx={{ mx: '19px' }} type='submit' variant='contained' endIcon={ <Send /> }>Submit</Button>
          </DialogActions>
        </form>

        { /* 
        <DialogActions sx={ { justifyContent: 'left', p: '5px 24px' } }>
          {isRegistered ? 'Do you already have an account?' : 'No account? Sign up'}
          <Button onClick={ () => setIsRegistered(prevValue => !prevValue)}>
            { isRegistered ? 'Login' : 'Register' }
          </Button>
        </DialogActions>
        <DialogActions sx={{ justifyContent: 'center', py: '24px' }}>
          <GoogleOneTapLogin />
        </DialogActions>
         */ }
      </Dialog>
    </div>;

  return result;
}

export default Login;
