import { useState } from 'react';
import { Avatar, InputAdornment, TextField } from '@mui/material';
import { useValue } from '../../../context/ContextProvider';
import pendingIcon from './icons/progress1.svg';
import { Check } from '@mui/icons-material';

let timer;

function  InfoField({ mainProps, optionalProps = {}, minLength })
{
  const { dispatch } = useValue();
  const [ editing, setEditing ] = useState(false);
  const [ error, setError ] = useState(false);
  const [ success, setSuccess ] = useState(false);

  function handleChange(e)
  {
    dispatch({ type: 'UPDATE_DETAILS', payload: { [e.target.name]: e.target.value } });

    if (!editing)
    {
      setEditing(true);
    }

    clearTimeout(timer);
    
    function validateInput()
    {
      setEditing(false);

      if (e.target.value.length < minLength)
      {
        if (!error)
        {
          setError(true);
        }
        if (success)
        {
          setSuccess(false);
        }
      }
      else
      {
        if (error)
        {
          setError(false);
        }
        if (!success)
        {
          setSuccess(true);
        }
      }
    }

    timer = setTimeout(validateInput, 1000);
  };

  const result =
    <TextField {...mainProps} {...optionalProps} error={error}
      helperText={error && `This field must be ${minLength} characters or more...`}
      color={success ? 'success' : 'primary'} variant='outlined' onChange={ handleChange } required
      InputProps={{
        endAdornment: (
          <InputAdornment postion='end'>
            { editing ? <Avatar src={ pendingIcon } sx={{ height: 70 }} /> : success && <Check color='success'/> }
          </InputAdornment>
        ),
      }}
    />;

  return result;
};

export default InfoField;