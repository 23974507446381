import { useState, useEffect, useCallback } from 'react';
import { Box, Button, Container, Stack, Step, StepButton, Stepper } from '@mui/material';
import { Cancel, Send } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import { useValue } from '../../context/ContextProvider';
import AddDetails from './addDetails/AddDetails';
import AddImages from './addImages/AddImages';
import AddLocation from './addLocation/AddLocation';
import { clearRoom, createRoom, updateRoom } from '../../actions/room';

function AddRoom()
{
  const { state: { images, details, location, currentUser, updatedRoom, deletedImages, addedImages }, dispatch } = useValue();
  const [activeStep, setActiveStep] = useState(0);
  const initialSteps =
  [
    { label: 'Location', completed: false },
    { label: 'Details', completed: false },
    { label: 'Images', completed: false }
  ];
  const [showSubmit, setShowSubmit] = useState(false);
  const [steps, setSteps] = useState(initialSteps);

  function handleNext()
  {
    if (activeStep < steps.length - 1)
    {
      setActiveStep((activeStep) => activeStep + 1);
    }
    else
    {
      const stepIndex = findUnfinished();
      
      setActiveStep(stepIndex);
    }
  };

  function checkDisabled()
  {
    let result;

    if (activeStep < steps.length - 1)
    {
      result = false;
    }
    else
    {
      const index = findUnfinished();
  
      if (index !== -1)
      {
        result = false;
      }
      else
      {
        result = true;
      }
    }

    return result;
  };

  function findUnfinishedFn()
  {
    return steps.findIndex((step) => !step.completed);
  };

  const findUnfinished= useCallback(findUnfinishedFn, [steps]);

  function setComplete(index, status)
  {
    setSteps((steps) =>
    {
      steps[index].completed = status;
      return [...steps];
    });
  };

  function effectValidateLocation()
  {
    if (location.lng || location.lat)
    {
      if (!steps[0].completed)
      {
        setComplete(0, true);
      }
    }
    else
    {
      if (steps[0].completed)
      {
        setComplete(0, false);
      }
    }
  }

  function effectValidateDetails()
  {
    if (details.title.length > 4 && details.description.length > 9)
    {
      if (!steps[1].completed)
      {
        setComplete(1, true);
      }
    }
    else
    {
      if (steps[1].completed)
      {
        setComplete(1, false);
      }
    }
  }

  function effectValidateImages()
  {
    if (images.length)
    {
      if (!steps[2].completed)
      {
        setComplete(2, true);
      }
    }
    else
    {
      if (steps[2].completed)
      {
        setComplete(2, false);
      }
    }
  }

  function effectValidateAll()
  {
    const unfinished = findUnfinished();

    if (unfinished === -1)
    {
      if (!showSubmit)
      {
        setShowSubmit(true);
      }
    }
    else
    {
      if (showSubmit)
      {
        setShowSubmit(false);
      }
    }
  }

  
  useEffect(effectValidateLocation, [location, steps]);
  useEffect(effectValidateDetails, [details, steps]);
  useEffect(effectValidateImages, [images, steps]);
  useEffect(effectValidateAll, [steps, showSubmit, findUnfinished]);

  function generateStep(step, index)
  {
    const result =
      <Step key={ step.label } completed={step.completed }>
        <StepButton onClick={ () => setActiveStep(index) }>
          { step.label }
        </StepButton>
      </Step>;

    return result;
   }

  function handleSubmit()
  {
    const room =
    {
      lng         : location.lng,
      lat         : location.lat,
      price       : details.price,
      title       : details.title,
      description : details.description,
      images
    };

    if (updatedRoom)
    {
      updateRoom(room, currentUser, dispatch, updatedRoom, deletedImages);
    }
    else
    {
      createRoom(room, currentUser, dispatch);
    }
  }

  const navigate = useNavigate();

  function handleCancel()
  {
    if (updatedRoom)
    {
      navigate('/dashboard/rooms');
      clearRoom(dispatch, currentUser, addedImages, updatedRoom);
    }
    else
    {
      dispatch({ type: 'UPDATE_SECTION', payload: 0 });
      clearRoom(dispatch, currentUser, images);
    }
  }

  const stepList = steps.map(generateStep);

  const result =
    <Container sx={{ my: 4 }}>
      <Stepper alternativeLabel nonLinear activeStep={ activeStep } sx={{ mb: 3 }}>
        { stepList }
      </Stepper>
      <Box sx={{ pb: 7 }}>
        {
          {
            0: <AddLocation />,
            1: <AddDetails />,
            2: <AddImages />,
          }[activeStep]
        }
        <Stack direction='row' sx={{ pt: 2, justifyContent: 'space-around' }}>
          <Button color='inherit' disabled={ !activeStep }
            onClick={ () => setActiveStep((activeStep) => activeStep - 1) }>
            Back
          </Button>
          <Button disabled={ checkDisabled() } onClick={ handleNext }>
            Next
          </Button>
        </Stack>

        <Stack  sx={{ alignItems: 'center', justifyContent: 'center', gap: 2 }} direction="row">
          { showSubmit && (
            <Button variant="contained" endIcon={ <Send /> } onClick={ handleSubmit }>
              { updatedRoom ? 'Update' : 'Submit' }
            </Button>
          )}
          <Button variant="outlined" endIcon={ <Cancel /> } onClick={ handleCancel }>
            Cancel
          </Button>
        </Stack>
      </Box>
    </Container>;
    
    return result;
};

export default AddRoom;