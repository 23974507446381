async function fetchData({ url, method='POST', token='', body=null }, dispatch)
{
  let result;
  const headers = token
    ? { 'Content-Type': 'application/json', authorization: `Bearer ${token}` }
    : { 'Content-Type': 'application/json' };

  body = body ? { body: JSON.stringify(body) } : {};
  try
  {
    const response = await fetch(url, { method, headers, ...body });
    const data = await response.json();
    
    if (!data.success)
    {
      if (response.status === 401)
      {
        dispatch({ type: 'UPDATE_USER', payload: null });
      }
      throw new Error(data.message);
    }

    result = data.result;
  }
  catch (error)
  {
    dispatch({ type: 'UPDATE_ALERT', payload: { open: true, severity: 'error', message: error.message + '..'} });
    console.log('error:', error);

    result = null;
  }

  return result;
};

export default fetchData;