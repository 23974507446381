import { Fragment, useState } from 'react';
import { AppBar, Container, IconButton, Toolbar, Box, Typography, Button } from '@mui/material';
import { Menu, Lock } from '@mui/icons-material';
import { useValue } from '../context/ContextProvider';
import Sidebar from './sidebar/Sidebar';
import UserIcons from './user/UserIcons';

function NavBar()
{
  const { state : { currentUser}, dispatch } = useValue();
  const [isOpen, setIsOpen] = useState(false);

  function handleClickLogin()
  {
    dispatch({ type: 'OPEN_LOGIN' });
  }

  function handleClickDrawerOpen()
  {
    setIsOpen(true);
  }

  const result =
    <Fragment>
      <AppBar>
        <Container maxWidth='lg'>
          <Toolbar disableGutters>
            <Box sx={{ mr:1 }}>
              <IconButton size='large' color='inherit' onClick={ handleClickDrawerOpen }>
                <Menu />
              </IconButton>
            </Box>
            <Typography variant='h6' component='h1' noWrap sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }}}>
              Travel
            </Typography>
            <Typography variant='h6' component='h1' noWrap sx={{ flexGrow: 1, display: {xs: 'flex', md: 'none' }}}>
              Travel
            </Typography>
            { !currentUser ? 
              (<Button color='inherit' startIcon={ <Lock /> } onClick={ handleClickLogin }>Login</Button>) :
              (<UserIcons />)
            }
          </Toolbar>
        </Container>
      </AppBar>
      <Toolbar />
      <Sidebar {...{ isOpen, setIsOpen }} />
    </Fragment>;

  return result;
}

export default NavBar;
