import { Box, Drawer, IconButton, styled, Typography } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import { useValue } from '../../context/ContextProvider';
import PriceSlider from './PriceSlider';

const DrawerHeader = styled('div')(({ theme }) => ({
  display        : 'flex',
  alignItems     : 'center',
  justifyContent : 'space-between',
  padding        : theme.spacing(0, 1),
  ...theme.mixins.toolbar
}));

function Sidebar({ isOpen, setIsOpen })
{
  const { containerRef } = useValue();

  function handleClickDrawerClose()
  {
    setIsOpen(false);
  }
  
  const result =
    <Drawer variant='persistent' hideBackdrop={ true } open={ isOpen }>
      <DrawerHeader>
        <Typography>Search or Filter:</Typography>
        <IconButton onClick={ handleClickDrawerClose }>
          <ChevronLeft fontSize='large' />
        </IconButton>
      </DrawerHeader>
      <Box sx={{ width: 240, p: 3 }}>
        <Box ref={ containerRef }></Box>
        <PriceSlider />
      </Box>
    </Drawer>;

  return result;
};

export default Sidebar;