function reducer(state, action)
{
  function applyFilter(rooms, address, price)
  {

    console.log('reducer.js > applyFilter | address:', address);
    console.log('reducer.js > applyFilter | price:', price);

    let filteredRooms = rooms;

    if (address)
    {
      const { lng, lat } = address;

      function filterCallback(room)
      {
        const lngDifference = lng > room.lng ? lng - room.lng : room.lng - lng;
        const latDifference = lat > room.lat ? lat - room.lat : room.lat - lat;

        return lngDifference <= 1 && latDifference <= 1;
      }

      filteredRooms = filteredRooms.filter(filterCallback);
    }
  
    if (price < 50)
    {
      filteredRooms = filteredRooms.filter((room) => room.price <= price);
    }

    console.log('reducer.js > applyFilter | filteredRooms:', filteredRooms);

    return filteredRooms;
  };

  let result;

  switch (action.type)
  {
    case 'OPEN_LOGIN':
        result = { ...state, openLogin: true };
      break;
    case 'CLOSE_LOGIN':
        result = { ...state, openLogin: false };
      break;

    case 'START_LOADING':
        result = { ...state, loading: true };
      break;
    case 'END_LOADING':
        result = { ...state, loading: false };
      break;

    case 'UPDATE_ALERT':
        result = { ...state, alert: action.payload };
      break;

    case 'UPDATE_PROFILE':
        result = { ...state, profile: action.payload };
      break;
      
    case 'UPDATE_USER':
        localStorage.setItem('currentUser', JSON.stringify(action.payload));
        result = { ...state, currentUser: action.payload };
      break;

    case 'UPDATE_IMAGES':
        result = { ...state, images: [...state.images, ...action.payload] };
      break;

    case 'DELETE_IMAGE':
        result = { ...state, images: state.images.filter((image) => image !== action.payload) };
      break;

    case 'UPDATE_DETAILS':
        result = { ...state, details: { ...state.details, ...action.payload } };
      break;

    case 'UPDATE_LOCATION':
        result = { ...state, location: action.payload };
      break;

    case 'UPDATE_UPDATED_ROOM':
        result = { ...state, updatedRoom: action.payload };
      break;


    case 'UPDATE_DELETED_IMAGES':
        result = { ...state, deletedImages: [...state.deletedImages, ...action.payload] };
      break;

    case 'UPDATE_ADDED_IMAGES':
        result = { ...state, addedImages: [...state.addedImages, ...action.payload] };
      break;

    case 'RESET_ROOM':
        result = { ...state, images: [], details: { title: '', description: '', price: 0 }, location: { lng: 0, lat: 0 }, updatedRoom: null, deletedImages: [], addedImages: [] };
      break;

    case 'UPDATE_ROOMS':
        result = { ...state, rooms: action.payload, addressFilter: null, priceFilter: 50, filteredRooms: action.payload };
      break;


    case 'FILTER_PRICE':
        result = { ...state, priceFilter: action.payload, filteredRooms: applyFilter(state.rooms, state.addressFilter, action.payload ) };
      break;

    case 'FILTER_ADDRESS':
        result = { ...state, addressFilter: action.payload, filteredRooms: applyFilter(state.rooms, action.payload, state.priceFilter ) };
      break;

    case 'CLEAR_ADDRESS':
        result = { ...state, addressFilter: null, priceFilter: 50, filteredRooms: state.rooms };
      break;

    case 'UPDATE_ROOM':
        result = { ...state, room: action.payload };
      break;

    case 'UPDATE_USERS':
        result = { ...state, users: action.payload };
      break;

    case 'DELETE_ROOM':
        result = { ...state, rooms: state.rooms.filter((room) => room._id !== action.payload) };
      break;
    
    case 'UPDATE_SECTION':
        result = { ...state, section: action.payload };
      break;

    default:
        throw new Error(`reducer.js : action ${ action.type } does not exist...`);
  }

  return result;
}

export default reducer;