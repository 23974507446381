import { Fragment, useCallback, useState } from 'react';
import { Paper } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import ImagesList from './ImagesList';
import ProgressList from './progressList/ProgressList';

function AddImages()
{
  const [files, setFiles] = useState([]);
  const onDrop = useCallback((acceptedFiles) => { setFiles(acceptedFiles); }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: { 'image/*': [] }});

  const result =
    <Fragment>
      <Paper sx={{ cursor: 'pointer', background: '#fafafa', color: '#bdbdbd',
          border: '1px dashed #ccc', '&:hover': { border: '1px solid #ccc' } }}>
        <div style={{ padding: '16px' }} {...getRootProps()}>
          <input {...getInputProps()} />
          { isDragActive ?
            <p style={{ color: 'green' }}>Drop the files here...</p>
            :
            <p>Drag 'n' Drop some files here or click to select files</p> }
          <em>(Accepted image extensions: *.jpeg, *.png, *.jpg)</em>
        </div>
      </Paper>
      <ProgressList {...{ files }} />
      <ImagesList />
    </Fragment>;

  return result;
};

export default AddImages;