import deleteFile from '../../firebase/deleteFile';

async function deleteImages(images, userId)
{
  function deleteImage(imgURL)
  {
    const imgName = imgURL?.split(`${userId}%2F`)[1]?.split('?')[0];

    return deleteFile(`rooms/${userId}/${imgName}`);
  }

  if (images.length > 0)
  {
    const promises = images.map(deleteImage);

    try
    {
      await Promise.all(promises);
    }
    catch (error)
    {
      console.log(error);
    }
  }
};

export default deleteImages;